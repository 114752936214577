import React from 'react';
import { useResponsive, VStack } from '@sg/garnish';

import { useIsChatAppEnabled } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import type { AccountTabScreenId } from '../../../../../navigation/AppNavigation.props';
import { accountMenuStaticLinkConfig } from '../../../../../screens/AccountScreen/AccountScreen.navigation';
import { useSweetPassOptions } from '../../hooks/useSweetPassOptions';
import { AccountContactUsOption } from '../AccountContactUsOption';
import { AccountMenuLink } from '../AccountMenuLink';
import { AccountPrimaryMenuTokenCta } from '../AccountPrimaryMenuTokenCta';

export const AccountPrimaryMenu = (props: AccountPrimaryMenuProps) => {
  const { screenId, ...stackProps } = props;

  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();

  const isChatAppEnabled = useIsChatAppEnabled();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );
  const isGenerateTokenCtaEnabled = useFeatureFlag(
    'CELS-2061-enable-generate-tokens-cta',
  );

  const isReferFriendEnabled = useFeatureFlag('permanent-refer-friend-enabled');
  const { isSweetpassJoinItemVisible, isSweetpassMembershipItemVisible } =
    useSweetPassOptions();

  const shouldRenderContactUsOption =
    currentBreakpoint.isXS || !isChatAppEnabled;

  return (
    <VStack {...stackProps}>
      <AccountMenuLink
        testID="account.sidebar-tab.profile"
        label={t('account.sidebar.account-info')}
        iconName="IconProfileStroke"
        to={accountMenuStaticLinkConfig.profileLinkConfig}
        active={screenId === 'profile'}
      />

      {isSweetpassJoinItemVisible ? (
        <AccountMenuLink
          testID="account.sidebar-tab.sweetpass-join"
          label={t('account.sidebar.sweetpass-join')}
          iconName="IconLoyalty"
          tag={{
            title: t('account.sidebar.sweetpass-join.tag'),
            palette: 'primary',
          }}
          to={accountMenuStaticLinkConfig.sweetpassJoinLinkConfig}
          active={false}
        />
      ) : null}

      {isSweetpassMembershipItemVisible ? (
        <AccountMenuLink
          testID="account.sidebar-tab.sweetpass-membership"
          label={t('account.sidebar.sweetpass-membership')}
          iconName="IconLoyalty"
          to={accountMenuStaticLinkConfig.sweetpassMembershipLinkConfig}
          active={screenId === 'sweetpass-membership'}
        />
      ) : null}

      <AccountMenuLink
        label={
          isInAppGiftCardEnabled
            ? t('account.sidebar.payments')
            : t('account.sidebar.payment-and-gift-cards')
        }
        iconName="IconCreditCard"
        to={accountMenuStaticLinkConfig.paymentAndGiftCardsLinkConfig}
        active={screenId === 'payment-and-gift-cards'}
      />
      <AccountMenuLink
        label={t('account.sidebar.credit-and-promo-codes')}
        iconName="IconBadgeScan"
        to={accountMenuStaticLinkConfig.creditAndPromoCodesLinkConfig}
        active={screenId === 'credit-and-promo-codes'}
      />
      <AccountMenuLink
        testID="account.sidebar-tab.addresses"
        label={t('account.sidebar.addresses')}
        iconName="IconPickupStrokeSm"
        to={accountMenuStaticLinkConfig.addressesLinkConfig}
        active={screenId === 'addresses'}
      />

      <AccountMenuLink
        testID="account.sidebar-tab.orders"
        label={t('account.sidebar.orders')}
        iconName="IconBowl"
        to={accountMenuStaticLinkConfig.ordersLinkConfig}
        active={screenId === 'orders'}
      />

      <AccountMenuLink
        testID="account.sidebar-tab.favorites"
        label={t('account.sidebar.favorites')}
        iconName="IconHeartStroke"
        to={accountMenuStaticLinkConfig.favoritesLinkConfig}
        active={screenId === 'favorites'}
      />

      {isReferFriendEnabled ? (
        <AccountMenuLink
          testID="account.sidebar-tab.refer-friend"
          label={t('account.sidebar.refer-friend')}
          iconName="IconStarStroke"
          to={accountMenuStaticLinkConfig.referFriendLinkConfig}
          active={screenId === 'refer-friend'}
        />
      ) : null}

      <AccountMenuLink
        testID="account.sidebar-tab.dietary-restrictions"
        label={t('account.sidebar.dietary-restrictions')}
        iconName="IconFilter"
        to={accountMenuStaticLinkConfig.dietaryRestrictionsLinkConfig}
        active={screenId === 'dietary-restrictions'}
      />

      {shouldRenderContactUsOption ? <AccountContactUsOption /> : null}

      {isGenerateTokenCtaEnabled ? <AccountPrimaryMenuTokenCta /> : null}
    </VStack>
  );
};

type AccountPrimaryMenuProps = Readonly<{
  screenId?: AccountTabScreenId | null;
}> &
  Omit<React.ComponentProps<typeof VStack>, 'children'>;

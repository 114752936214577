import { useCallback } from 'react';
import { type AddressType } from '@sg/garnish';

import { useBraze } from '@order/Braze';
import {
  useCheckoutBrazeLogPurchase,
  useOrderStatusLiveActivity,
} from '@order/features/ordering';
import { useGlobalAppState } from '@order/GlobalAppState';
import {
  useInAppReview,
  useLocalOrderHistory,
  useOrderFeedbackNotifications,
} from '@order/hooks';
import { useOneSignal } from '@order/OneSignal';
import { usePlacedOrdersCounter } from '@order/PlacedOrdersCounter';

/**
 * Handles the actions that need to be taken post placing an order.
 */
export const usePostOrder = () => {
  const { scheduleOrderFeedbackNotification } = useOrderFeedbackNotifications();
  const { incrementPlacedOrdersCounter } = usePlacedOrdersCounter();
  const requestInAppReview = useInAppReview();
  const { addToLocalOrderHistory } = useLocalOrderHistory();

  const { dynamicFeatureFlags } = useGlobalAppState();
  const { isLiveActivitiesEnabled } = dynamicFeatureFlags;

  const { logPurchase } = useBraze();
  const logBrazePurchase = useCheckoutBrazeLogPurchase({ logPurchase });

  const { addDataTag } = useOneSignal();
  const { startOrderStatusLiveActivity } = useOrderStatusLiveActivity({
    isEnabled: isLiveActivitiesEnabled,
    addDataTag,
  });

  return useCallback(
    (params: PostOrderParams) => {
      const {
        orderId,
        orderChannel,
        canTrackOrderStatus,
        locationName,
        wantedTime,
        restaurantId,
        lineItems,
        total,
        subtotal,
        discountTotal,
      } = params;

      startOrderStatusLiveActivity({
        orderId,
        orderChannel,
        canTrackOrderStatus,
        locationName,
        wantedTime,
      });

      void scheduleOrderFeedbackNotification({ orderId, wantedTime });

      addToLocalOrderHistory({ orderId });

      incrementPlacedOrdersCounter();

      requestInAppReview();

      logBrazePurchase({
        restaurantId,
        lineItems,
        orderChannel,
        total,
        subtotal,
        discountTotal,
      });
    },
    [
      addToLocalOrderHistory,
      incrementPlacedOrdersCounter,
      logBrazePurchase,
      requestInAppReview,
      scheduleOrderFeedbackNotification,
      startOrderStatusLiveActivity,
    ],
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type PostOrderParams = {
  restaurantId: string;
  total: number;
  subtotal: number;
  discountTotal: number;
  lineItems: readonly LineItem[] | undefined | null;
  orderId: string;
  orderChannel: AddressType;
  canTrackOrderStatus: boolean;
  locationName: string;
  wantedTime: string; // ISO date string
};

type LineItem = { product?: { name?: string } };

import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useInterpret } from '@xstate/react';
import { useNoticeBannersStackContext } from '@sg/garnish';
import {
  type DietaryPropertyKind,
  type DietaryRestriction,
} from '@sg/graphql-schema';

import { useUpdateCustomerPreferences } from '@order/hooks';

import { dietaryRestrictionsMachine } from './dietaryRestrictionsMachine';
import { setMachinePersistedState } from './dietaryRestrictionsMachine.utils';
import {
  useDietaryRestrictionsFromAsyncStorage,
  useDietaryRestrictionsFromICloudStorage,
} from './hooks';

// ────────────────────────────────────────────────────────────────────────────

export const useDietaryRestrictionsInterpret = () => {
  const { updateCustomerPreferences } = useUpdateCustomerPreferences();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { formatMessage } = useIntl();

  // ─── Updating Restrictions ─────────────────────────────────────────────────

  const updateRemoteRestrictions = useCallback(
    async (context: (typeof dietaryRestrictionsMachine)['context']) => {
      const restrictionsMap = context.restrictions;

      const dietaryRestrictions = Object.keys(restrictionsMap).filter(
        (restriction) => restrictionsMap[restriction as DietaryPropertyKind],
      ) as DietaryRestriction[];

      const { error } = await updateCustomerPreferences({
        dietaryRestrictions,
      });

      if (error) {
        addNoticeBanner({
          text: formatMessage(messages.updateRestrictionsFailure),
          palette: 'caution',
        });

        return { success: false };
      }

      addNoticeBanner({
        text: formatMessage(messages.updateRestrictionsSuccess),
        palette: 'success',
      });

      return { success: true };
    },
    [addNoticeBanner, formatMessage, updateCustomerPreferences],
  );

  // ───────────────────────────────────────────────────────────────────────────

  const interpreter = useInterpret(dietaryRestrictionsMachine, {
    actions: {
      onConfirmRestrictions: setMachinePersistedState,
    },
    services: {
      updateRemoteRestrictions,
    },
  });

  useDietaryRestrictionsFromICloudStorage(interpreter);
  useDietaryRestrictionsFromAsyncStorage(interpreter);

  return interpreter;
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  updateRestrictionsSuccess: {
    defaultMessage: 'Your dietary restrictions are saved!',
    description: 'Dietary Restrictions | Update | Success',
  },
  updateRestrictionsFailure: {
    defaultMessage: 'Something went wrong updating your dietary restrictions.',
    description: 'Dietary Restrictions | Update | Failure',
  },
});
